export const capitalize = (str) => {
  if(str && typeof str === 'string') {
    str = str.charAt(0).toUpperCase() + str.slice(1)
  }
  return str;
}

export const copyText = (string) => {
  const el = document.createElement('textarea');  
  el.value = string;                                 
  el.setAttribute('readonly', '');                
  el.style.position = 'absolute';                     
  el.style.left = '-9999px';                      
  document.body.appendChild(el);                  
  const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;                                    
  el.select();                                    
  document.execCommand('copy');                   
  document.body.removeChild(el);                  
  if (selected) {                                 
    document.getSelection().removeAllRanges();    
    document.getSelection().addRange(selected);   
  }
}

export const decodeEntities = (str) => {
  if(str && typeof str === 'string') {
    var element = document.createElement('div');
    // strip script/html tags
    str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
    str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
    element.innerHTML = str;
    str = element.textContent;
    element.textContent = '';
  }
  return str;
}

export const randomHash = (length) => {
  // Generates a random string between min/max length
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  var result           = '';
  for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const randomString = (min, max) => {
  // Generates a random string between min/max length
  var length = Math.floor(Math.random() * (max - min + 1) + min)
  var characters       = 'abcdefghijklmnopqrstuvwxyz'
  if(length > 8) characters += '     ' //add spaces for longer strings
  var charactersLength = characters.length
  var result           = '';
  for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return app.utility.capitalize(result)
}

export const stripTags = (string) => {
  return string.replace(/(<([^>]+)>)/gi, "")
}

export const truncateWords = (string, length) => {
  // Truncates string to number of words (or returns entire string if short enough)
  if(!string) return ''
  let split = string.split(' ')
  if(split.length <= length) return string
  // Remove trailing punctuation before appending ellipsis
  if(split[length-1].slice(-1) === '.' || split[length-1].slice(-1) === ',') split[length-1] = split[length-1].slice(0, -1)
  return split.splice(0, length).join(' ') + '...'
}
export const breakpointSize = () => {
  if(window.matchMedia("(min-width: 1400px)").matches) return 'xxl'
  if(window.matchMedia("(min-width: 1200px)").matches) return 'xl'
  if(window.matchMedia("(min-width: 992px)").matches) return 'lg'
  if(window.matchMedia("(min-width: 768px)").matches) return 'md'
  if(window.matchMedia("(min-width: 576px)").matches) return 'sm'
  if(window.matchMedia("(min-width: 0px)").matches) return 'xs'
}